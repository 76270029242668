<template>
  <div class="standard-page">
    <!-- Show the button to go back -->
    <v-btn
      v-if="!isDialogView"
      text
      class="mb-4"
      color="primary"
      @click="$router.go(-1)"
    >
      <v-icon left>
        arrow_back
      </v-icon>

      Back
    </v-btn>

    <v-card flat class="pt-8 px-md-6">
      <v-card-text class="mb-4">
        <v-row>
          <v-col cols="12" md="4" lg="3">
            <!-- Show the campaign logo if available -->
            <div class="checkerboard-background min-width-100" style="height: 88px;">
              <v-img
                v-if="campaignLogo"
                :src="campaignLogo"
                height="88"
                class="mb-4 checkerboard-background"
                contain
              />
            </div>
          </v-col>

          <v-col cols="12" md="8" lg="9">
            <v-card
              outlined
              class="d-flex justify-space-between align-center"
            >
              <div>
                <v-card-title>
                  Upload Campaign Logo
                </v-card-title>

                <v-card-subtitle :class="{ 'red--text': $v.formData.logo.$anyError }">
                  Please select an image under 1MB
                </v-card-subtitle>
              </div>

              <v-card-actions class="pr-4">
                <!-- Show the upload logo field -->
                <input
                  type="file"
                  name="campaign-tracking-logo"
                  accept=".png, .jpeg, .jpg"
                  class="d-none"
                  ref="campaignTrackingLogoInput"
                  @change="handleLogoChange"
                />

                <v-btn
                  color="primary"
                  depressed
                  @click="$refs.campaignTrackingLogoInput && $refs.campaignTrackingLogoInput.click()"
                  :loading="isLoading"
                  :disabled="isLoading || isValidating"
                >
                  <v-icon left>image</v-icon>
                  Upload
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <!-- Show a divider -->
        <v-divider class="my-8" />

        <v-row>
          <v-col cols="12" md="6">
            <!-- Show the input here -->
            <v-text-field
              v-model="formData.name"
              :hide-details="!$v.formData.name.$anyError"
              :error-messages="$v.formData.name.$anyError ? ['Please enter a valid campaign name'] : null"
              @blur="$v.formData.name.$touch"
              label="Campaign Name"
              outlined
            />
          </v-col>

          <v-col cols="12" md="6">
            <!-- Show the input for hashtags -->
            <v-combobox
              v-model="formData.hashtags"
              label="Hashtags"
              :allow-overflow="true"
              placeholder="#EndOfTheReasonSale #BigBillionDays #AmazonPrimeDay"
              multiple
              outlined
              small-chips
              deletable-chips
              hint="Mention tags related to your campaign, press enter to add more"
              :error-messages="$v.formData.hashtags.$anyError ? [getHashtagErrorMessage()] : null"
              @focus="didEditData = true"
              @input="handleHashtagInput"
            ></v-combobox>
          </v-col>

          <!-- Input: Start date -->
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-menu
              v-model="dialog.shouldShowStartDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="readableStartDate"
                  :hide-details="!$v.formData.startDate.$anyError"
                  :error-messages="$v.formData.startDate.$anyError ? ['Please select a date'] : null"
                  @blur="$v.formData.startDate.$touch"
                  :readonly="!shouldDateRangeBeEditable"
                  :disabled="!shouldDateRangeBeEditable"
                  label="Start Date"
                  prepend-inner-icon="today"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                show-current
                show-adjacent-months
                :min="dayjs().subtract(89, 'day').format('YYYY-MM-DD')"
                :max="formData.endDate ? dayjs(formData.endDate).format('YYYY-MM-DD') : null"
                v-model="formData.startDate"
                @input="dialog.shouldShowStartDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Input: End date -->
          <v-col
            cols="12"
            md="6"
            lg="3"
          >
            <v-menu
              v-model="dialog.shouldShowEndDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="readableEndDate"
                  :hide-details="!$v.formData.endDate.$anyError"
                  :error-messages="$v.formData.endDate.$anyError ? ['Please select a valid date'] : null"
                  @blur="$v.formData.endDate.$touch"
                  :readonly="!shouldDateRangeBeEditable"
                  :disabled="!shouldDateRangeBeEditable"
                  label="End Date"
                  prepend-inner-icon="event"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                show-current
                show-adjacent-months
                :min="formData.startDate ? dayjs(formData.startDate).format('YYYY-MM-DD') : null"
                :max="dayjs().add(180, 'day').format('YYYY-MM-DD')"
                v-model="formData.endDate"
                @input="dialog.shouldShowEndDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            v-if="model"
            cols="12"
            md="6"
            lg="3"
          >
            <v-select
              v-model="formData.status"
              label="Campaign Status"
              :disabled="isLoading"
              :loading="isLoading"
              :items="statusOptions"
              hide-details
              outlined
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
            :lg="model ? 3 : 4"
          >
            <!-- Show the platforms selector -->
            <div style="margin-top: -16px">
              <div class="mb-2">
                Social Networks
              </div>
              <div class="d-flex items-center">
                <v-img
                  v-for="platform in Object.keys(formData.platforms)"
                  :key="platform"
                  :class="{ 'opaque': !formData.platforms[platform] }"
                  @click="() => (formData.platforms[platform] = !formData.platforms[platform])"
                  :src="`/img/socials/${platform}.svg`"
                  class="mr-3 pointer"
                  max-width="40"
                  height="40"
                ></v-img>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- Show a few radio buttons for hashtag condition -->
        <div v-if="formData.hashtags.length > 1">
          <v-divider class="my-4" />

          <!-- Show the text -->
          <div class="text-subtitle-1">
            You've entered multiple hashtags, should the crawler fetch posts that contain all of them or any of them?
          </div>

          <v-radio-group
            v-model="formData.hashtagCondition"
          >
            <v-radio
              value="any"
              label="Any of them"
            ></v-radio>

            <v-radio
              value="all"
              label="All of them"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>

      <v-card-actions class="pl-4 pb-8">
        <v-tooltip
          v-if="!model"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-chip
              label
              color="primary"
              v-on="on"
            >
              <v-icon
                left
                small
              >
                account_balance
              </v-icon>

              {{ nFormatter(availableModuleUsage) }} Reports
            </v-chip>
          </template>

          <span>
            You can generate {{ availableModuleUsage }} more reports
          </span>
        </v-tooltip>

        <v-spacer />

        <v-btn
          depressed
          color="primary"
          @click="validate"
          :disabled="availableModuleUsage <= 0 || isLoading || isValidating"
          :loading="isLoading || isValidating"
        >
          {{ didEditData ? "Continue" : (model ? "Update Campaign" : "Create Campaign") }}

          <v-icon right>
            arrow_forward
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength } from "vuelidate/lib/validators"

// Import child components
const AgeSelector = () => import(/* webpackChunkName: "age-selector" */ "@/blocks/common/selectors/AgeSelector.vue")
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// Default form data object
const initialFormData = () => ({
  name: "",
  logo: null,
  startDate: "",
  endDate: "",
  hashtags: [],
  status: null,
  platforms: {
    instagram: true,
    youtube: false,
    tiktok: false
  },
  // The condition for multiple hashtags
  hashtagCondition: "any",
})

// Export the SFC
export default {
  // Name of the component
  name: "CampaignTrackingForm",

  // Register children components
  components: {
    AgeSelector,
    WeightSelector
  },

  // Accept incoming data from parent
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    model: {
      type: Object,
      required: false,
      default: null
    },

    isDialogView: {
      type: Boolean,
      required: false,
      default: false
    },

    platforms: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not is validating the form
    isValidating: false,

    // If we edited the form data
    didEditData: true,

    // The visible menu dialogs
    dialog: {
      shouldShowStartDate: false,
      shouldShowEndDate: false
    },

    // Get the status option values
    statusOptions: [
      {
        text: "Pending",
        value: "pending"
      },
      {
        text: "In Progress",
        value: "in-progress"
      },
      {
        text: "Cancelled",
        value: "cancelled"
      },
      {
        text: "Completed",
        value: "completed"
      }
    ],

    // The form data to be used
    formData: initialFormData(),

    // Define the preview image
    campaignLogo: null,
  }),

  // Define computable readonly variables
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("campaign-tracking")
    },

    /**
     * Get the formatted value from startDate
     *
     * @returns {String|Null}
     */
    readableStartDate() {
      return this.formData.startDate ? dayjs(this.formData.startDate).format("ll") : null
    },

    /**
     * Get the formatted value from endDate
     *
     * @returns {String|Null}
     */
    readableEndDate() {
      return this.formData.endDate ? dayjs(this.formData.endDate).format("ll") : null
    },

    /**
     * Get the current campaign's logo
     *
     * @returns {String}
     */
    currentCampaignLogo() {
      return this.model && this.model.logo ? `${process.env.VUE_APP_STORAGE_BASE}/${this.model.logo}` : null
    },

    /**
     * If the model exists, and the startDate value is more than 90 days ago
     * Don't let the user edit this value
     *
     * @returns {Boolean}
     */
    shouldDateRangeBeEditable() {
      return this.model ? dayjs().diff(dayjs(this.model.start_date), 'day') <= 90 : true
    }
  },

  // Define validations for this form
  validations() {
    // Initiate the object
    const object = {
      formData: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(200)
        },
        hashtags: {
          required
        },
        logo: {
          maxSize: (value) => {
            return value ? value.size <= 1_000_000 : true // Maximum 1MB in size
          },
          mimeType: (value) => {
            return value ? ["image/png", "image/jpeg"].includes(value.type) : true
          }
        },
        startDate: {},
        endDate: {},
        status: {}
      }
    }

    // If we can edit the dates
    if (this.shouldDateRangeBeEditable) {
      // Add the validations
      object.formData.startDate = {
        required,
        minimum: (v) => dayjs().diff(dayjs(v), 'day') <= dayjs().diff(dayjs().subtract(3, "month").format("YYYY-MM-DD"), 'day')
      }

      object.formData.endDate = {
        required,
        minimum: (v) => dayjs(v).diff(this.formData.startDate, 'day') >= 1
      }
    }

    // If the model exists
    if (this.model) {
      // Add the status validation
      object.formData.status = {
        required
      }
    }

    // Return the object
    return object
  },

  // Watch for any changes
  watch: {
    formData: {
      deep: true,
      handler() {
        // If we have edited the data
        if (this.didEditData === false) {
          // Update the flag
          this.didEditData = true
        }
      }
    },

    platforms: {
      deep: true,
      immediate: true,
      handler() {
        // If the platforms are not empty
        if (this.platforms.length) {
          // Go through each platform
          for (const platform in this.formData.platforms) {
            // If the platform is in props
            if (this.platforms.includes(platform)) {
              // Set the value to true
              this.formData.platforms[platform] = true
            }
            // Otherwise
            else {
              // Set the value to false
              this.formData.platforms[platform] = false
            }
          }
        }
      }
    }
  },

  // Define method functions
  methods: {

    /**
     * Run the validations and if correct
     * If valid, emit to the parent
     *
     * @returns {void}
     */
    async validate() {
      // Start isValidating
      this.isValidating = true

      // If the user made some form changes
      if (this.didEditData === true) {
        // Wait for 200 milliseconds
        await wait(200)

        // Run the validations
        await this.$v.formData.$touch()

        // if it is valid
        if (!this.$v.formData.$anyError) {
          // Hide the didEdit text
          this.didEditData = false
        }

        // End the validating
        this.isValidating = false

        // End further execution
        return
      }

      // Check if the inputs are valid
      await this.$v.formData.$touch()

      // If it is invalid
      if (this.$v.formData.$anyError) {
        // Hide the validating
        this.isValidating = false

        return
      }

      // If none of the platform is selected
      if (Object.values(this.formData.platforms).filter(v => v).length === 0) {
        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Please select a social network for this campaign" })

        // Hide the validating
        this.isValidating = false

        // Stop further execution
        return
      }

      // Create the formData object
      const formData = new FormData()

      // Add the name
      formData.append("name", this.formData.name)
      // Add the hashtags
      formData.append("hashtags", JSON.stringify(this.formData.hashtags.map((item) => item.replaceAll("#", "").trim())))
      // Add the platforms
      formData.append("platforms", JSON.stringify(Object.keys(this.formData.platforms).filter((key) => this.formData.platforms[key])))
      // Add the hashtag condition
      formData.append("hashtag_condition", this.formData.hashtagCondition)
      // Add the startDate
      formData.append("start_date", this.formData.startDate)
      // Add the endDate
      formData.append("end_date", this.formData.endDate)

      // If the model exists
      if (this.model) {
        // Append the status value
        formData.append("status", this.formData.status)
      }

      // Compute what to do with the logo
      formData.append("logo_action", this.formData.logo === null ? "ignore" : "update")

      // If we don't have a logo to begin with, don't send anything
      if (this.formData.logo !== null) {
        formData.append("logo_file", this.formData.logo)
      }

      // Otherwise, emit the event
      this.$emit("submit", formData)

      // Hide the validating
      this.isValidating = false
    },

    /**
     * Handle the hashtag input
     *
     * @returns {void}
     */
    handleHashtagInput() {
      // Go through each hashtag, and remove the # from it and then add again at the start
      this.formData.hashtags = this.formData.hashtags
        // Remove all the hash symbols
        .map((item) => item.replaceAll("#", "").trim())
        // Add the hash symbol again
        .map((item) => `#${item}`)
        // Extract the value from input
        .map((item) => {
          // Get the value
          const value = regex.hashtag.exec(item)

          // If the value is not null
          if (value !== null) {
            // Return the value
            return value[0]
          }
          // Otherwise
          else {
            // Return null
            return null
          }
        })
        // Filter out the null values
        .filter((item) => item !== null)
        // Remove the duplicates
        .filter((item, index, self) => self.indexOf(item) === index)

      // Touch the input
      this.$v.formData.hashtags.$touch()
    },

    /**
     * Get the error message for hashtag input
     *
     * @returns {String}
     */
    getHashtagErrorMessage() {
      // If the user has not entered any hashtags
      if (this.formData.hashtags.length === 0) {
        // Return the error message
        return "Please enter at least one hashtag"
      }

      // If the user has entered more than 50 hashtags
      if (this.formData.hashtags.length > 50) {
        // Return the error message
        return "Please enter at most 50 hashtags"
      }

      // If the user has entered invalid hashtags
      if (this.$v.formData.hashtags.$anyError) {
        // Return the error message
        return "Please enter valid hashtags"
      }

      // Otherwise, return null
      return null
    },

    /**
     * Handle the event when the image changes in input
     *
     * @param {Object} event
     * @returns {void}
     */
    async handleLogoChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })
      }

      // Get the file object
      this.formData.logo = event.target.files[0] || null

      // Run the validations
      await this.$v.formData.logo.$touch()

      // If an image is selected
      if (this.formData.logo && this.formData.logo instanceof File) {
        this.campaignLogo = URL.createObjectURL(this.formData.logo)
      }
      // If an image has been removed, fallback to default
      else {
        this.campaignLogo = this.currentCampaignLogo
      }
    },
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // If we have a model data already
    if (this.model) {
      // Update the formData with these values
      this.formData = {
        name: this.model.name,
        logo: null,
        hashtags: (this.model.hashtags || []).map((item) => `#${item}`),
        startDate: this.model.start_date,
        endDate: this.model.end_date,
        status: this.model.status,
        platforms: {
          instagram: this.model.platforms.includes("instagram"),
          youtube: this.model.platforms.includes("youtube"),
          tiktok: this.model.platforms.includes("tiktok")
        },
        hashtagCondition: this.model.hashtag_condition,
      }

      // Update the campaign logo
      this.campaignLogo = this.currentCampaignLogo
    }
  }
}
</script>

<style lang="stylus" scoped>
.opaque
  opacity 0.2

.contain-select-width
  min-width unset !important
  width unset !important
</style>
